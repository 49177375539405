<template>
  <div>
    <div :class="isPadding ? 'row m-0 p-0' : 'row mr-2 mt-3 mb-2 ml-2'" class="align-items-center">

      <div class="" v-if="tableProperties.shipmentDetailsShowButton">
        <button class="delete-button mr-2">
          <span class="px-2" @click="deleteOrderInShipment">Delete</span>
          <img src="@/assets/delete-red.svg" class="icon-class" />
        </button>
      </div>

      <!-- for product dashboard table style with dropdown and search  -->

      <div :class="[{ 'mr-auto': !isShipmentPage }, 'col-lg-1', 'col-md-1', 'col-6', 'pl-0', 'filter-btn']" id="filterBtn" v-if="isFilterButton"  >
        <img src="../assets/SVG/desktopFilter.svg" style="cursor:pointer" @click="openDesktop_filter"> 
        <span style="color:#2E8CD3; font-size:14px;cursor:pointer;"  @click="openDesktop_filter">Filters</span>
      </div>
      <div class="border-left pl-4 mr-auto" style="color:#2E8CD3; font-size:14px;cursor:pointer" v-if="isShipmentPage" @click="openAutoScheduleModal"><img style="margin: 0 5px 2px 0" height="17px" src="../assets/settings-blue.svg">Shipment Settings</div>
      <div class="col-lg-3 col-md-5 col-6 performance-dropdown align-items-center p-0" v-if="isProductDashboard">
        <w-dropdown
          :label-text="''"
          :selectedValue="null"
          :selectOption="performanceOptions"
          @selectedOption="onChangePerformance($event)"
        />
      </div>
      <div class="col-lg-3 col-md-5 col-12 search-input align-items-center" v-if="isProductDashboard">
        <div class="mobile-input-search">
          <b-form-input
            type="search"
            placeholder="Search by product title..."
            class="product-search-input"
            @keyup="getProductFilter($event)"
          ></b-form-input>
        </div>
        <img src="../assets/Search.svg" class="search-icon" />
      </div>
        
        
      <div class="col-lg-4 col-xl-3 col-md-4 col-6 py-0 text-right ml-auto generate-report-btn" v-if="shipmentType === 'genreateReportButton'">
        <span @click="generateReport" class="generate-report">
        <img style="" src="../assets/add.png" alt="add"> Generate New Report</span>
      </div>
      <div class="col-lg-4 col-xl-3 col-md-4 col-6 py-0 text-right ml-auto" v-if="shipmentType === 'uploadMtr'">
        <span @click="generateReport" class="generate-report">
        <img style="" src="../assets/add.png" alt="add"> Upload MTR</span>
      </div>
      <span class="float-right mt-3" v-if="showUserSearchFilter">
        <router-link to="/AddUser">
          <button type="submit" class="adduser_btn float-right" v-if="isPermission('allow_add_new_user') || isPermission('allow_manage_own_company')">
            <img src="../assets/add.png" class="userIcon" /> Add User
          </button>
        </router-link>
      </span>
      <!-- button for add role -->
      <span class="float-right mt-3" v-if="isaddRole">
        <button
          type="submit"
          class="adduser_btn float-right"
          @click="openModalAddRolePer('data', 'newRole')"
          v-if="isPermission('allow_add_role') || isPermission('allow_manage_own_company')"
        >
          <img src="../assets/add.png" class="userIcon" /> Add Role
        </button>
      </span>
        <!-- for shipment buttons -->
        <!-- <div class="col-lg-2 col-md-2 col-2 justify-content-end mx-4 my-2" v-if="tableProperties.shipmentShowButton">
          <button class="mr-4" @click="deleteShipment" v-if="tableProperties.shipmentShowButton">
            <img src="@/assets/delete.svg" style="filter: brightness(0)" />
          </button>
          <button @click="markShippedBulk">
            <img src="../assets/SVG/ShipmentHigh.svg" />
          </button>
        </div> -->

        <!-- <div class="d-flex align-items-center pagination-div ml-auto"> -->
          <!-- <div > -->
      <div class="col-lg-3 col-md-2 col-5 justify-content-end align-items-center d-flex desktop-download-ship-icons" v-if="tableProperties.showButton || tableProperties.shipmentShowButton">
        <!-- <button > -->
        <img class="px-1" 
          src="@/assets/delete.svg" 
          style="filter: brightness(0); cursor:pointer"
          @click="deleteShipment" 
          v-b-popover.hover.top="'Delete'"
          v-if="tableProperties.shipmentShowButton" 
        />
        <!-- </button> -->
        <img 
          class="px-1"
          v-if="tableProperties.showButton"
          src="../assets/SVG/downloadDesk.svg" 
          style="cursor:pointer" 
          @click="downloadOrder"
          v-b-popover.hover.top="'Download Orders'"
        />
        <img 
          class="px-1"
          src="../assets/SVG/ShipmentHigh.svg" 
          style="cursor:pointer" 
          v-show="tableProperties.showCreateShipmentBtn || tableProperties.shipmentShowButton"
          @click="createShipment"
          v-b-popover.hover.top="tableProperties.shipmentShowButton ? 'Mark Shipped' : 'Create Shipment'"
        />
      </div>
        <!-- for returns download in order tabs start -->
        <!-- <div class="col-lg-1 col-md-1 col-3 mr-4 desktop-download-ship-icons" v-if="tableProperties.showReturnDownloadBtn">
          <img 
              src="../assets/SVG/downloadDesk.svg" 
              style="cursor:pointer" 
              @click="downloadOrderReturns"
              v-b-popover.hover.top="'Download Orders'"
            />
        </div> -->
        <!-- end -->
      <!-- </div> -->
      <div class="col-lg-5 col-md-4 col-6  justify-content-end align-items-center d-flex desktop-download-ship-icons" v-if="isCustomerDetails && isPermission('allow_to_download_customer_details')">
        <w-button-input 
            :img="'require'"
            :ImgSrc="'download.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="downloadGenerateManifest"
            :label="'Customer Details'"
          />
      </div>
      <div class="col-lg-4 col-md-6 col-12 justify-content-between align-items-right my-2 table-pagination" v-if="tableProperties.totalRows >= 5">
            <span
              class="show-class mx-1"
              style="font-size:14px;"
              v-if="tableProperties.totalRows >= 5"
              >Show</span>
            <b-form-select
              v-if="tableProperties.totalRows >= 5"
              style="width: 60px; box-shadow: none;font-size:12px;box-shadow: 0px 1px 10px #40404029;"
              v-model="tableProperties.perPage"
              :options="getPerPageOptions(tableProperties.totalRows)"
              @change="getSelectedPerPage($event)"
            ></b-form-select>
      <!-- </div> -->
      <!-- <div class="col-lg-3 col-md-3 col-8 float-right align-items-right p-0 my-2 d-flex justify-content-end" v-if="tableProperties.totalRows >= 5"> -->
            <span 
              class="show-class ml-1" 
              v-if="tableProperties.totalRows >= 5"
              style="font-size:14px;"
            > 
              Entries 
            </span>
            <b-pagination
              v-if="tableProperties.totalRows >= 5"
              :total-rows="tableProperties.totalRows"
              :per-page="tableProperties.perPage"
              :value="tableProperties.currentPage"
              :limit="1"
              aria-controls="my-table"
              @change="getSelectedPage($event)"
            ></b-pagination>
      </div>
        <!-- </div> -->
      <!-- </div> -->
    </div>

    <!-- for shipment type is MARKETPLACE_LOGISTICS -->
    <div class="m-2 shipment-details"
      v-if="shipmentType == 'MARKETPLACE_LOGISTICS' && (isPermission('allow_upload_template_schedule_pickup') || isPermission('allow_download_template_schedule_pickup'))" >
      <div class="d-flex align-items-center p-3 button-row">
        <div v-if="isPermission('allow_download_template_schedule_pickup')">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'download.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="downloadShipmentTemplate('MARKETPLACE_LOGISTICS')"
            :label="'Download Template'"
          />
        </div>
        <div class="ml-3" v-if="isPermission('allow_upload_template_schedule_pickup')">
          <w-button-input
            :img="'require'"
            :ImgSrc="'uploadicon.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            :label="'Upload'"
            @buttonClicked="openUploadModel('easyshipTemplate')"
          />
        </div>
        
      </div>
    </div>

    <!-- for shipment type is SELFSHIP -->
    <div class="m-2 shipment-details" v-if="shipmentType == 'SELFSHIP'">
      <div class="d-flex justify-content-between align-items-center p-3 button-row">

        <!-- Adding the switcher kind of button back for toggling between generate invoices and confirm shipment recovered from a old commit -->
        <div class="mr-auto">
          <div class="button-groups">
            <button
              style="min-width:165px"
              :class="{ activeTab: isTab == 'generateInvoiceLabel' }"
              @click="toggleShipmentButtons('generateInvoiceLabel')"
            >
              Generate Invoice & Label
            </button>
            <button
              :class="{ activeTab: isTab == 'confirmShipment' }"
              @click="toggleShipmentButtons('confirmShipment')"
            >
              Confirm Shipment
            </button>
          </div>
        </div>

        <!-- for confirm shipment -->
        <div class="mr-2" v-if="isConfirmShipment && isPermission('allow_download_template_for_confirm_shipment_for_self_ship')">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'download.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="downloadConfirmShipment()"
            :label="'Download Template'"
          />
        </div>
        <div v-if="isConfirmShipment && isPermission('allow_upload_template_for_confirm_shipment_for_self_ship')">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'uploadicon.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="openUploadModel('confirmShipment')"
            :label="'Upload'"
          />
        </div>
        
        <!-- for label and invoice -->
        <div class="mr-2" v-if="isLabelAndInvoice && isPermission('allow_download_template_for_create_shipment_in_shiprocket')">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'download.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="downloadShipmentTemplate('SELFSHIP')"
            :label="'Download Template'"   
          />
        </div>
        <div v-if="isLabelAndInvoice && isPermission('allow_upload_template_for_create_shipment_in_shiprocket')">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'uploadicon.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="openUploadModel('selfshipTemplate')"
            :label="'Upload'"
          />
        </div>
        <!-- 
        Changed the label name of above button to download template
        TODO: need to connect to correct method ✓
        update: found the method downloadShipmentTemplate() and passing 'SELFSHIP' as argument - seems to be working
                need to conform and verify with Bala
        -->

        <!-- Below three buttons - schedule pickup, download manifest, cancel shipment are common in all cases under SELFSHIP type -->
        <div class="ml-auto mr-2" v-if="isPermission('allow_schedule_pickup_for_self_ship')">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'van.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="scheduleShipRocketOrder()"
            :label="'Schedule Pickup'"
          />
        </div>
        <div class="mr-2" v-if="isPermission('allow_download_manifest')">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'download.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="downloadGenerateManifest()"
            :label="'Download Manifest'"
          />
        </div>
        <div class="" v-if="isPermission('allow_cancel_shipment_in_shiprocket')">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'cancelCircle.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="cancelShipmentOrders()"
            :label="'Cancel Shipment'"
          />
        </div>

        <!-- Commented out the below button as it looks redundant -->
        <!-- <div class="mx-2 pr-3">
          <w-button-input 
            :img="'require'"
            :ImgSrc="'cancelCircle.svg'"
            :buttonClass="'shipment-buttons'"
            :buttonStyle="'auth-button-style'"
            @buttonClicked="cancelShipmentOrders()"
            :label="'Cancel Shipment'"
          />
        </div> -->
               
      </div>
    </div>

    <div class="userTable">
      <b-card :class="isPadding ? 'm-0 px-3' : 'ml-2 mr-2 mb-3 mt-2 px-3'">
        
        <b-table
          :filterIncludedFields="filterFields"
          :filter="filterSearch"
          thead-class="theadClass"
          id="my-table"
          :items="tableProperties.items"
          :fields="tableProperties.fields"
          :busy="tableLoading"
          empty-text="No Records Found"
          show-empty
          outlined
          class="mt-3 tableStyle"
          :tbody-transition-props="transProps"
          :sticky-header="true"
          style="max-height:800px; overflow-x:auto;"
          responsive
        
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(sellerSellableQuantity)="data">
            <label
              class="link-label"
              @click="onSellerStockCLick(data.item)"
            >
              {{ data.item.sellerSellableQuantity }}
            </label>
          </template>
          <template v-slot:cell(history)="data">
            <label
              class="link-label"
              v-if="data.item.history"
              @click="goToStockHistory(data.item)"
            >
              {{ data.item.history }}
            </label>
          </template>

          <!-- this is for GST view MTR table channel Name -->
          <template v-slot:cell(channelName)="data" v-if="isViewMTR">
            {{ data.item.channelName }}
          </template>
          <!-- this is for dashboard operation listing table channel Name -->
          <template
            v-slot:cell(channelName)="data"
            v-else-if="isListingOperation"
          >
            {{ data.item.channelName }}
          </template>

          <template v-slot:cell(channelName)="data" v-else>
            <img
              :src="data.item.marketplaceLogUrl"
              style="width:25px"
            />
            {{ data.item.channelName }}
          </template>
          <template v-slot:cell(purchaseDate)="data">
            {{data.item.purchaseDate}}
          </template>
          <!-- revenue for product dashboard -->
          <template v-slot:cell(revenue)="data">
            {{ data.item.currency }} {{ data.item.revenue }}
          </template>

          <template v-slot:head(orderId)="data">{{
            data.field.label
          }}</template>
          <template v-slot:head(checkbox)="{ item }">
            <label class="checkbox">
              <input
                type="checkbox"
                :key="item"
                v-model="tableProperties.allSelected"
                :value="tableProperties.allSelected"
                @change="getCheckedValue()"
              />
              <span class="primary"></span>
            </label>
          </template>
          <template v-slot:cell(checkbox)="{ item }">
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="item.selected"
                :key="item"
                @change="getRowCheckedValue()"
              />
              <span class="primary"></span>
            </label>
          </template>
          <!-- <template v-slot:cell(orderId)="data">
            <span class="link-label" @click="goToOrderDetailsPage(data.item)">
              {{ data.item.orderId }}
            </span>
          </template> -->
          <template v-slot:cell(orderId)="data">
            <label
              class="link-label"
              v-if="data.item.orderId"
              @click="goToOrderDetailsPage(data.item)"
              >{{ data.item.orderId }}</label
            >
            <br v-if="data.item.orderId" />
            {{ data.item.channelOrderId }}
          </template>
          <!-- for returns page start-->
          <template v-slot:cell(masterOrderId)="data">
            <label
              class="link-label"
              v-if="data.item.masterOrderId"
              @click="goToOrderDetailsPage(data.item)"
              >{{ data.item.masterOrderId }}</label
            >
            <br v-if="data.item.masterOrderId" />
            {{ data.item.channelOrderId }}
          </template>

          <template v-slot:cell(orderTotal)="data">
            <span style="white-space:nowrap">
              {{ !!data.item.orderTotal ? `₹ ${data.item.orderTotal}` : "" }}
            </span>
          </template>
          <!-- end -->
          <template v-slot:cell(shipmentId)="data">
            <span class="link-label" @click="goToShipmentDetails(data.item)">
              {{ data.item.shipmentId }}
            </span>
          </template>
          <template v-slot:cell(actiond_field)="data">
            <div v-if="data.item.pickupSlot" >
              <div v-if="shipmentType === 'MARKETPLACE_LOGISTICS'">
                <img role="button" src="../assets/SVG/label.svg" 
                  @click="downLoadShipDetailsLabels(data.item)" 
                  v-b-popover.hover.top="'Labels'" 
                  v-if="isPermission('allow_download_label')"
                />
                <img class="px-3" role="button" src="../assets/SVG/invoice.svg"  
                  @click="downLoadShipDetailsInvoices(data.item)" 
                  v-b-popover.hover.top="'Invoices'" 
                  v-if="isPermission('allow_download_invoice')"
                />
                <img role="button" src="../assets/SVG/labelAndInvoice.svg" 
                  @click="downLoadShipDetailsInvoicesAndLabels(data.item)" 
                  v-b-popover.hover.top="'Labels And Invoices'" 
                  v-if="isPermission('allow_download_label_invoice')"
                />
              </div>
              <div v-if="shipmentType === 'SELFSHIP'">
                <img role="button" src="../assets/SVG/label.svg" 
                  @click="downLoadShipDetailsLabels(data.item)" 
                  v-b-popover.hover.top="'Labels'" 
                  v-if="isPermission('allow_download_label_for_self_ship')"
                />
                <img class="px-3" role="button" src="../assets/SVG/invoice.svg"  
                  @click="downLoadShipDetailsInvoices(data.item)" 
                  v-b-popover.hover.top="'Invoices'" 
                  v-if="isPermission('allow_download_invoice_for_self_ship')"
                />
              </div>
            </div>
          </template>
          <template v-slot:cell(action_field)="data">
            <div style="margin:2px; cursor:pointer; color: #5fa6e7;">
              <button
                @click="receiveEvent(data.item)"
                style="border:1px solid #2e8cd3; padding:3px"
              >
                Recieve
              </button>
            </div>
          </template>
          <template v-slot:cell(shipBy)="data">
            <span style="white-space: nowrap">{{data.item.shipBy.split('to')[0]}}</span>
            <span v-if="data.item.shipBy.includes('to')"><br>to<br></span>
            <span style="white-space: nowrap">{{data.item.shipBy.split('to')[1]}}</span>
          </template>
          <template v-slot:cell(deliverBy)="data">
            <span style="white-space: nowrap">{{data.item.deliverBy.split('to')[0]}}</span>
            <span v-if="data.item.shipBy.includes('to')"><br>to<br></span>
            <span style="white-space: nowrap">{{data.item.deliverBy.split('to')[1]}}</span>
          </template>
          <template v-slot:cell(remitence_Action)="data">
            <div style="margin:2px; cursor:pointer; color: #5fa6e7;" v-if="isPermission('allow_download_remittance_reports')">
              <button
                @click="Download(data.item)"
              >
                <img src="../assets/SVG/downloadDesk.svg" v-b-popover.hover="'Download Remittance Reports'" >
              </button>
            </div>
          </template>
          <template v-slot:cell(action_generate)="data">
            <div style="margin:2px; cursor:pointer; color: #5fa6e7;">
              <button
                class="ml-3"
                @click="getGenerate(data.item.id)"
                style="border:1px solid #2e8cd3; padding:3px; width:100px"
              >
                Generate
              </button>
            </div>
          </template>
          <template v-slot:cell(action_generate_field)="data">
            <!-- <div style="margin:2px; cursor:pointer; color: #5fa6e7;">
              <button
                class="ml-3"
                @click="getDownloadReport(data.item.reportId)"
                style="border:1px solid #2e8cd3; padding:3px; width:100px"
              >
                Download
              </button>
            </div> -->
            <img 
                class="px-1"
                id="download-report"
                @click="getDownloadReport(data.item.reportId)"
                src="../assets/SVG/downloadDesk.svg" 
                style="cursor:pointer" 
                v-if="isPermission('allow_download_inventory_report')"
              />
          </template>

          <!-- action link for shipment upload -->
          <template v-slot:cell(action_shipment)="data">
            <div class="mb-0 ">
              <img src="../assets/SVG/processingReport.svg"
                id="processing-report"
                style="cursor:pointer" 
                @click="processingReportClick(data.item.batchId)"
                v-b-popover.hover.top="'Processing Report'"
              >
              <img src="../assets/SVG/downloadDesk.svg" 
                id="download-labe"
                class="ml-3"
                style="cursor:pointer" 
                @click="downloadLableClick(data.item.batchId)"
                v-b-popover.hover.top="'Download Labels'"
                v-if="hideDownloadForListing"
              >
            </div>
          </template>
          <template v-slot:cell(labelStatus)="data">
            {{
              data.item.labelStatus === true
                ? "Label generated"
                : "Not generated"
            }}
          </template>

          <!-- view button for GST view Mtr table -->
          <template v-slot:cell(view_mtr_btn)="data">
            <div style="margin:2px; cursor:pointer; color: #5fa6e7;" v-if="isPermission('allow_to_view_mtr_reports')">
              <button
                class="ml-3"
                @click="getViewMtrsReport(data.item.reportId)"
                style="border:1px solid #2e8cd3; padding:3px; width:100px"
              >
                VIEW
              </button>
            </div>
          </template>
          <!-- end view mtr -->

          <template v-slot:cell(actionn_user_field)="data">
            <div class="d-flex justify-content-center">
              <!-- <router-link to="/EditUser"> -->
              <img
                v-if="isPermission('allow_edit_user') || isPermission('allow_manage_own_company')"
                src="@/assets/edit.svg"
                @click="editRowDataEvent(data.item)"
                style="cursor:pointer"
              />
              <!-- </router-link> -->
              <!-- <span
                v-if="data.item.type === 'MARKETPLACE'"
                class="mr-3 pr-3"
              ></span> -->
              <img
                v-if="isPermission('allow_delete_users') || isPermission('allow_manage_own_company')"
                src="@/assets/delete-red.svg"
                @click="deleteRowDataEvent(data.item)"
                class="ml-3"
                style="color:red; cursor:pointer"
              />
            </div>
          </template>
          <template v-slot:cell(role_permissions)="data">
            <span
              v-if="isPermission('allow_add_or_change_permissions_of_the_role') || isPermission('allow_manage_own_company')"
              class="add-change-role"
              @click="openModalAddRolePer(data.item, 'permission')"
            >
              <!-- <img src="../assets/plus.png" width="15px" />&emsp; -->
              <span style="padding-top:2px">View Permissions</span>
            </span>
          </template>
          <template v-slot:cell(action_roleUser_field)="data">
            <div class="d-flex justify-content-around" style="margin:2px" v-if="isPermission('allow_manage_own_company')">
              <!-- <img
                style="cursor:pointer"
                src="@/assets/edit.svg"
                @click="editRowDataEvent(data.item)"
              /> -->
              <img
                style="cursor:pointer; filter: grayscale(100%)"
                src="@/assets/delete-red.svg"
                @click="deleteRowDataEvent(data.item)"
              />
            </div>
          </template>
          <!-- user role start -->
          <template v-slot:cell(user_role)="data">
            <div v-if="data.item.roles && data.item.roles.length > 0 && data.item.roles[0] !==null">
              <span v-for="(rolNames, i) in data.item.roles" :key="i">
                <span class="roles-style ml-1">
                  {{ rolNames.roleDisplayName }}
                </span>
              </span>
            </div>
          </template>

          <template v-slot:cell(check_active_user)="data">
            <span v-if="data.item.active == true">ACTIVE</span>
            <span v-else>INACTIVE</span>
          </template>

          <template v-slot:cell(add_change_user)="data">
            <span class="add-change-role" @click="openRoleModal(data)" v-if="isPermission('allow_add_or_change_roles_of_the_user') || isPermission('allow_manage_own_company')">
              <!-- <img src="../assets/plus.png" width="15px" />&emsp; -->
              <span style="padding-top:2px">View Roles</span>
            </span>
          </template>

          <!-- user role end -->
          <template v-slot:cell(settlementPeriod)="data">
            <span
              v-if="isPermission('allow_view_settlement_period_details')"
              class="link-column-item"
              @click="getIndividualRemittenceDetails(data.item)"
            >
              {{ data.item.settlementPeriod }}
              <!-- {{ formatDate(data.item.settlementStart, "DD-MMM-YYYY") }} to
              {{ formatDate(data.item.settlementEnd, "DD-MMM-YYYY") }} -->
            </span>
          </template>
          <template v-slot:cell(previousReserveAmount)="data">
            <span>
              <span v-if="data.item.previousReserveAmount != null">{{
                data.item.currency
              }}</span>
              {{ data.item.previousReserveAmount }}
            </span>
          </template>
          <template v-slot:cell(remittedAmount)="data">
            <span>
              <span v-if="data.item.remittedAmount != null">{{
                data.item.currency
              }}</span>
              {{ data.item.remittedAmount }}
            </span>
          </template>
          <template v-slot:cell(currencyReserveAmount)="data">
            <span>
              <span v-if="data.item.currencyReserveAmount != null">{{
                data.item.currency
              }}</span>
              {{ data.item.currencyReserveAmount }}
            </span>
          </template>
          <template v-slot:cell(orderIds)="data">{{
            data.item.orderIds && data.item.orderIds.length
          }}</template>
          <template v-slot:cell(orderShipmentBatchId)="data">
            <label
              class="link-label"
              @click="goToShipmentDetailsPage(data.item)"
              v-if="isPermission('allow_view_shipment_details')"
              >{{ data.item.orderShipmentBatchId }}</label
            >
          </template>
          <template v-slot:cell(channelOrderId)="data">
            <label
              class="link-label"
              v-if="data.item.masterOrderId"
              @click="goToOrderDetailsPage(data.item)"
              >{{ data.item.masterOrderId }}</label
            >
            <br v-if="data.item.masterOrderId" />
            {{ data.item.channelOrderId }}
          </template>

          <template v-slot:cell(totalAmount)="data">
            <label
              >{{ data.item.currencyToken }} {{ data.item.totalAmount }}</label
            >
          </template>

          <template v-slot:cell(shipByFromDate)="data">
            {{ data.item.shipByFromDate }}
            <br />to
            <br />
            {{ data.item.shipByToDate }}
          </template>
          <!-- shipment detail table changes for selfship start -->
          <template v-slot:head(orderProductMappingDtoList)="">
            <span v-if="shipmentType == 'SELFSHIP'">Confirm Shipment</span>
            <span v-else>SKU/Title</span>
          </template>
          <template v-slot:cell(orderProductMappingDtoList)="data">
            <span v-if="shipmentType == 'SELFSHIP'">
              <!-- <img src="../assets/wrong.png" width="14"/> <br/> -->
              <img src="../assets/right.png" />
            </span>
            <span
              v-else
              v-for="item in data.item.orderProductMappingDtoList"
              :key="item"
            >
              {{ item.sku }}<br />
            </span>
          </template>
          <!-- end -->

          <template v-slot:cell(customer)="data"
            ><span v-if="data.item.customer && data.item.customer.address">{{
              data.item.customer &&
                data.item.customer.address &&
                data.item.customer.address.city
            }}</span></template
          >
          <!-- <template v-slot:cell(quantity)="data">
            {{
              data.item.orderProductMappingDtoList.length
                ? data.item.orderProductMappingDtoList &&
                  data.item.orderProductMappingDtoList[0].quantity
                : ""
            }}
          </template> -->
          <template v-slot:cell(orderStatus)="data">
            <button
              :class="getStatusClass(data.item.orderStatus)"
              style="cursor:default"
            >
              {{ data.item.orderStatus }}
            </button>
          </template>
          <template v-slot:cell(status)="data">
            <button
              :class="getStatusClass(data.item.status)"
              style="cursor:default"
            >
              {{ data.item.status }}
            </button>
          </template>
          <template v-slot:cell(remittanceDate)="data">{{
            formatDate(data.item.remittanceDate, "DD MMM, YYYY")
          }}</template>
          <template v-slot:cell(createdOn)="data">
            {{ formatDate(data.item.createdOn, "DD MMM, YYYY") }}
            <br />
          </template>
          <!-- <template v-slot:cell(createdBy)="data">
            {{ getUserName(data.item.createdBy) }}
          </template> -->

          <!-- shipment detail table changes for selfship start -->
          <template v-slot:head(pickupTime)="">
            <span v-if="shipmentType == 'SELFSHIP'">Pickup Details</span>
            <span v-else>Pickup Time</span>
          </template>
          <template v-slot:cell(pickupTime)="data">
            <span
              style="color:#2e8cd3; cursor:pointer"
              v-if="shipmentType == 'SELFSHIP'"
              @click="showPickUpTimeDetail(data.item)"
            >
              View
            </span>
            <span v-else>
              {{ getPickupSlotDate(data) }}<br />{{ getPickupSlotTime(data) }}
            </span>
          </template>
          <template v-slot:head(quantity)="">
            <span v-if="shipmentType == 'SELFSHIP'"></span>
            <span v-else>Quantity</span>
          </template>
          <template v-slot:cell(quantity)="data">
            <span v-if="shipmentType == 'SELFSHIP'"> </span>
            <span v-else>
              {{
                data.item.orderProductMappingDtoList.length
                  ? data.item.orderProductMappingDtoList &&
                    data.item.orderProductMappingDtoList[0].quantity
                  : ""
              }}
            </span>
          </template>
          <!-- for customers table -->
          <template v-slot:row-details="row">
            <div class="mx-2">
              <div class="collapse-inline-table">
                <table class="table ">
                  <thead>
                    <tr class="table-bg">
                      <th class="table-bg" scope="col" style="color:#2e8cd3">
                        S. No
                      </th>
                      <th class="table-bg" scope="col" style="color:#2e8cd3">
                        Channel
                      </th>
                      <th class="table-bg" scope="col" style="color:#2e8cd3">
                        SKU
                      </th>
                      <th class="table-bg" scope="col" style="color:#2e8cd3">
                        Title
                      </th>
                      <th class="table-bg" scope="col" style="color:#2e8cd3">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in row.item.products" :key="index">
                      <td>{{index + 1}}</td>
                      <td>{{row.item.channelName}}</td>
                      <td style="text-align:center">{{data.sku}}</td>
                      <td>{{data.productTitle}}</td>
                      <td>{{data.quantity}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
          
          <template v-slot:cell(customer_products)="row">
            <span
              v-if="isPermission('allow_to_view_products')"
              @click="row.toggleDetails"
              style="color:#2E8CD3; cursor:pointer"
            >
              <span class="link-column-item" style="white-space:
              ">
                View Products
                <span v-if="row.detailsShowing" class="ml-1">&#9650;</span>
                <span v-else class="ml-1">&#9660;</span>
              </span>
            </span>
          </template>
          <template v-slot:cell(customer_action)="data">
            <img class="px-1"
              @click="deleteCustomer(data.item)" 
              src="@/assets/delete.svg" 
              style="filter: brightness(0); cursor:pointer"
              v-if="isPermission('allow_to_delete_customer_details')"
            />
          </template>
          <template v-slot:cell(customer_address)="data">
            {{data.item.addressLine1 }} {{ data.item.addressLine2 }} {{ data.item.city }} {{ data.item.state }} {{ data.item.pincode }}
          </template>
          <template v-slot:cell(sellingPrice)="data">
            <span class="text-nowrap">{{data.item.sellingPrice}}</span>
          </template>
          <template v-slot:cell(mrp)="data">
            <span class="text-nowrap">{{data.item.mrp}}</span>
          </template>
          <!-- end -->
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Dropdown from "../widgets/Dropdown";
import ButtonInput from "../widgets/InputButton.vue";
// import { component } from 'vue/types/umd';
// import { getUserList } from "../Service/CommonService";
import { isAuthorized } from "../Service/CommonService";

export default {
  components: {
    "w-dropdown": Dropdown,
    "w-button-input": ButtonInput,
  },

  props: [
    "tableProperties",
    "showUserSearchFilter",
    "tableLoading",
    "isPadding",
    "shipmentType",
    "pickUpSlotDATA",
    "isViewMTR",
    "isListingOperation",
    "filterSearch",
    "filterFields",
    // "shipmentIDs",
    "hideDownloadForListing",
    "isaddRole",
    // for performance dropdown and search in product dashboard
    "isProductDashboard",
    "performanceOptions",
    "isFilterButton",
    // "isCustomerDetails",
    "permissionShipment",
    "permissionDelete",
    "isShipmentPage"
  ],
  data() {
    return {
      transProps: {
        // Transition name
        name: "flip-list",
      },
      // userList: [],
      isTab: "generateInvoiceLabel",
      isLabelAndInvoice: true,
      isConfirmShipment: false,
    };
  },
  // created() {
  //   this.getUserList();
  // },
  methods: {
    // permission based validation
    isPermission(key){
      return isAuthorized(key)
    },
    openAutoScheduleModal() {
      this.$emit('onShipmentSettings');
    },
    getPickupSlotDate(data) {
      if (data.item.pickupSlot) {
        return moment(data.item.pickupSlot.fromTime).format("DD-MM-YYYY");
      } else {
        return "";
      }
    },
    getPickupSlotTime(data) {
      if (data.item.pickupSlot) {
        return (
          moment(data.item.pickupSlot.fromTime).format("hh:mm:A") +
          " - " +
          moment(data.item.pickupSlot.toTime).format("hh:mm:A")
        );
      } else {
        return "";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "Shipped":
          return "shipped-status-class";
        case "Unshipped":
          return "unshipped-status-class";
        case "Canceled":
          return "canceled-status-class";
        case "Pending":
          return "pending-status-class";
        default:
          return "";
      }
    },
    // getUserName(userId) {
    //   let userName = "";
    //   this.userList.forEach((user) => {
    //     if (user.value == userId) {
    //       userName = user.text;
    //     }
    //   });
    //   return userName;
    // },
    // getUserList() {
    //   this.userList = getUserList();
    // },
    
    // getImage(image) {
    //   var images = require.context("../assets/", false, /\.svg$/);
    //   return images("./" + image + ".svg");
    // },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    receiveEvent(data) {
      this.$emit("receiveEvent", data);
    },
    Download(data) {
      this.$emit("downloadEvent", data);
    },
    getGenerate(reportId) {
      this.$emit("generateEvent", reportId);
    },
    getIndividualRemittenceDetails(data) {
      this.$emit("getIndividualRemittenceDetails", data);
    },
    goto(event) {
      this.$emit("receive", event);
    },
    getRowCheckedValue() {
      this.tableProperties.allSelected = this.tableProperties.items.every(
        (item) => item.selected
      );
    },
    getCheckedValue() {
      if (this.tableProperties.allSelected) {
        this.tableProperties.items.forEach((item) => {
          item.selected = true;
        });
      } else {
        this.tableProperties.items.forEach((item) => {
          item.selected = false;
        });
      }
    },
    getPerPageOptions() {
      let options = [];
      options.push({ text: "5", value: 5 });
      for (let index = 5; index < 100; ) {
        index = index + 5;
        options.push({ text: index, value: index });
      }
      return options;
    },
    getSelectedPage(event) {
      this.$emit("getSelectedPageEvent", event);
    },
    getSelectedPerPage(event) {
      this.$emit("getSelectedPerPageEvent", event);
    },
    goToOrderDetailsPage(event) {
      this.$emit("goToOrderDetailsPageEvent", event);
    },
    onSellerStockCLick(event) {
      this.$emit("onSellerStockCLick", event);
    },
    goToStockHistory(event) {
      this.$emit("goToStockHistory", event);
    },
    downloadOrder() {
      this.$emit("downloadOrderEvent");
    },
    // downloadOrderReturns() {
    //   this.$emit("downloadOrderReturnsEvent");
    // },
    getDownloadReport(event) {
      this.$emit("downloadReport", event);
    },
    // for view Mtrs
    getViewMtrsReport(reportId) {
      this.$emit("viewMtrReportTable", reportId);
    },
    // for upload shipment
    processingReportClick(batchId) {
      this.$emit("processingReportClicked", batchId);
    },
    downloadLableClick(batchId) {
      this.$emit("downloadLableClicked", batchId);
    },

    createShipment() {
      this.$emit("createShipmentEvent");
    },
    goToShipmentDetailsPage(event) {
      this.$emit("goToShipmentDetailsPageEvent", event);
    },
    markShippedBulk() {
      this.$emit("markShippedBulkEvent");
    },
    
    deleteOrderInShipment() {
      this.$emit("deleteOrderInShipmentEvent");
    },
    deleteShipment() {
      this.$emit("deleteShipmentEvent");
    },
    deleteRowDataEvent(data) {
      this.$emit("deleteRowDataEvent", data);
    },
    downLoadShipDetailsInvoices(item,shipType) {
      let data={
        item:item,
        shipType:shipType
      }
      this.$emit("downLoadShipDetailsInvoicesEvent", data);
    },
    downLoadShipDetailsInvoicesAndLabels(item) {
      this.$emit("downLoadShipDetailsInvoicesAndLabelsEvent", item);
    },
    downLoadShipDetailsLabels(item, shipType) {
      let data={
        item:item,
        shipType:shipType
      }
      this.$emit("downLoadShipDetailsLabelsEvent", data);
    },

    goToShipmentDetails(shipment) {
      if (shipment.shipmentId) {
        sessionStorage.setItem("orderShipmentBatchId", shipment.shipmentId);
        sessionStorage.setItem("backToPage", "Order");
        this.$router.push("/shipment-details");
      }
    },

    editRowDataEvent(data) {
      this.$emit("editRowDataEvent", data);
    },
    // add or remove role
    openRoleModal(data) {
      this.$emit("openRoleModalEvent", data);
    },

    openModalAddRolePer(data, name) {
      let roleData = {
        data: data,
        name: name,
      };

      this.$emit("openModalAddRolePer", roleData);
    },

    // for selfship pickup time
    showPickUpTimeDetail(data) {
      this.$emit("showPickUpTimeDetailEvent", data);
    },
    // for performance dropdown and search in product dashboard
    onChangePerformance(event) {
      this.$emit("onChangePerformanceEvent", event);
    },
    getProductFilter(event) {
      this.$emit("getProductFilterEvent", event);
    },
    // for open filter in desktop layout
    openDesktop_filter(){
      this.$emit("openDesktop_filterEvent");
    },
    // For report page
    generateReport(){
      this.$emit("generateReport");
    },

    openUploadModel(event){
      this.$emit("openUploadModel", event);
    },
    downloadShipmentTemplate(event){
      this.$emit("downloadShipmentTemplate", event)
    },
    scheduleShipRocketOrder(){
      this.$emit("scheduleShipRocketOrder")
    },
    downloadGenerateManifest(){
      this.$emit("downloadGenerateManifest")
    },
    cancelShipmentOrders(){
      this.$emit("cancelShipmentOrders")
    },

    // toggle shipment buttons
    toggleShipmentButtons(tab) {
      this.isTab = tab;
      if (tab == "generateInvoiceLabel") {
       this.isLabelAndInvoice = true;
       this.isConfirmShipment = false;
      } else {
       this.isLabelAndInvoice = false;
       this.isConfirmShipment = true;
      }
    },

    downloadConfirmShipment(){
      this.$emit("downloadConfirmShipment");
    },
    // delete customer
    deleteCustomer(customerData){
      this.$emit("deleteCustomerEvent", customerData)
    }

  },
};
</script>

<style scoped>
::v-deep .dropdown-item {
  padding: 5px 0px 0px 0px !important;
}
::v-deep .dropdown-menu {
  padding: 0px !important;
  border: none !important;
}
::v-deep .dropdown-menu:focus {
  outline: none !important;
}
::v-deep .card-body {
  padding: 0px;
}
::v-deep .dropdown button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
::v-deep .td-checkbox-class {
  width: 50px;
}
::v-deep .theadClass {
  background-color: #efefef !important;
  text-align: center;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #1b1c1d;
}
::v-deep .table td {
  vertical-align: middle !important;
}
::v-deep .table thead th {
  vertical-align: middle !important;
  outline: none;
  color: #1B1C1D;
  font-size: 14px;
  font-family: "roboto-bold";
}
::v-deep td {
  padding-right: 16px;
  padding-left: 10px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  text-align: left;
  color: #1b1c1d;
}
::v-deep .custom-checkbox .custom-control-label::before {
  border: 1.5px solid #4d515d !important;
  border-radius: 3px !important;
  margin-top: -5px;
}
::v-deep .custom-control-label::after {
  top: 0px;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2185d0 !important;
}
::v-deep .custom-control-label {
  margin-top: 9px;
  vertical-align: middle !important;
}
::v-deep .thClass {
  text-align: center !important;
}
::v-deep .tdClass {
  text-align: center !important;
}
::v-deep .bv-no-focus-ring {
  outline: none;
}
.drop {
  width: 163px;
  height: 30px;
  background-color: #2e8cd3;
  margin-left: 20px;
}
.show-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  line-height: 2;
  text-align: left;
  color: #767676;
}
.link-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #2e8cd3;
  cursor: pointer;
}
.bordered-button {
  background-color: white;
  border: 1px solid #2e8cd3;
  padding: 6px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  text-align: left;
  color: #2e8cd3;
  height: 30px;
  border-radius: 2px;
}
.delete-button {
  background-color: white;
  border: 1px solid #ff4444;
  padding: 6px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  text-align: left;
  color: #ff4444;
  height: 30px;
  border-radius: 2px;
}
.icon-class {
  height: 13px;
  width: 13px;
}

.link-column-item {
  cursor: pointer;
  color: #2e8cd3;
}
.userTable ::v-deep .card{
  box-shadow: 0px 1px 3px #00000029
}
.userTable ::v-deep .card .card-body {
  display: flex;
  flex-direction: column;
}
/* for pagination style */
.pagination-section{
  margin-bottom: -4px;
}
.pagination-section ::v-deep .pagination {
  margin-bottom: 0;
}
.pagination-section ::v-deep .pagination .page-item .page-link {
  font-size: 25px;
  height: fit-content;
}
.pagination-section ::v-deep .pagination .active {
  text-align: center;
}
.pagination-section ::v-deep .pagination .active .page-link {
  font-size: 14px !important;
  padding: 11px 7px;
}
.generate-report{
  color: #2E8CD3;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}

.table-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-left: auto !important; */
}
</style>
<style>
/* table#my-table .flip-list-move {
  transition: transform 1s;
} */

.checkbox {
  background-color: #fff;
  display: inline-block;
  height: 18px;
  margin: 0 0.25em;
  width: 18px;
  border-radius: 4px;
  border: 1px solid #ccc;
  float: none;
  margin-top: 5px;
}
.checkbox span {
  display: block;
  height: 15px;
  position: relative;
  width: 7px;
  padding: 0;
}
.checkbox span:after {
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -webkit-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-right: 4px solid #fff;
  border-top: 4px solid #fff;
  content: "";
  display: block;
  height: 15px;
  left: 1px;
  position: absolute;
  top: 10px;
  width: 7px;
}
.checkbox span:hover:after {
  border-color: #2196f3;
}
.checkbox input {
  display: none;
}
.checkbox input:checked + span:after {
  -webkit-animation: check 0.8s;
  -moz-animation: check 0.8s;
  -o-animation: check 0.8s;
  animation: check 0.8s;
  border-color: #2196f3;
}
.checkbox input:checked + .default:after {
  border-color: #444;
}
.checkbox input:checked + .primary:after {
  border-color: #2196f3;
}
.adduser_btn {
  padding: 6px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  text-align: left;
  color: #2e8cd3;
  height: 30px;
  border-radius: 2px;
}
.roles-style {
  padding: 2px 4px;
  background: #1079db7d;
}
.add-change-role {
  color: #2e8cd3;
  cursor: pointer;
}
.add-change-role:hover {
  text-decoration: underline;
}
</style>

<style>
.tableStyle thead th {
  position: sticky !important;
  top: -1px !important;
  background-color: #efefef !important;
}
/* style for selfship dropdown btn */
/* .selfship-btns{
  width: 41.6%;
} */
.selfship-btns .btn-secondary {
  background: #fff;
  border: none !important;
  color: #2e8cd3;
}
.selfship-btns .show > .btn-secondary.dropdown-toggle {
  background: #fff;
  border: none !important;
  color: #2e8cd3;
}
.selfship-btns .btn-secondary:focus {
  background: #fff;
  border: none !important;
  color: #2e8cd3;
  box-shadow: unset !important;
}

.selfship-btns .btn-secondary:not(:disabled):not(.disabled):active:focus {
  background: #fff;
  border: none !important;
  color: #2e8cd3;
  box-shadow: unset !important;
}
.selfship-btns .dropdown-menu {
  /* width: 100%; */
  left: -6px !important;
  top: 5px !important;
}
.selfship-btns .dropdown-item {
  padding-top: 0px !important;
}
.selfship-btns .dropdown-menu a:hover {
  text-decoration: none;
}
.selfship-btns .dropdown-item:active {
  background: #fff;
}
.selfship-btns .dropdown-item button {
  display: flex !important;
  justify-content: flex-start !important;
}

.performance-dropdown .select_style .custom-select option:first-child {
  display: none !important;
}
.search-input .form-control {
  height: 35px !important;
}
.search-input {
  display: flex;
}
.mobile-input-search {
  width: 100%;
}
.search-icon {
  display: none;
}
.mobile-download-ship-icons{
  display: none;
}
  /* .desktop-download-ship-icons{
    justify-content: flex-end;
  } */

  .collapse-inline-table thead th {
    color: #fff;
    position: sticky;
    top: -1px;
    color: #1b1c1d !important;
    text-align: center;
  }
  .collapse-inline-table tbody td{
    text-align: center;
  }
  .collapse-inline-table {
    max-height: 300px;
    overflow: auto;
  }

/* for responsive */
@media only screen and (min-width:991px) and (max-width:1200px){
  .table-pagination{
    justify-content: flex-end !important;
  }
  .desktop-download-ship-icons{
    justify-content: center !important;
  }
  .generate-report-btn{
    text-align: left !important;
  }
}
@media only screen and (max-width: 1465px){
  .button-row{
    overflow-x: scroll;
  }
  .shipment-details ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
  .button-groups{
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  /* .show-class {
    margin-left: 16px !important;
  } */
  .mobile-input-search {
    opacity: 1;
  }
  /* .desktop-download-ship-icons{
    display: none !important;
  } */
  .mobile-download-ship-icons{
    display: block !important;
    /* width: 90px !important; */
    /* padding-right: 20px; */
  }
  .pagination-div{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .table-pagination{
    justify-content: space-evenly !important;
  }
  
  .button-row div{
    margin: 0 5px;
    display: flex;
  }
}

@media only screen and (max-width: 428px){
  .page-rows{
    margin-top: 10px;
  }
  .show-dropdown{
    flex-direction: column;
  }
}
.product-dashboard-item{
  align-items: flex-end;
}
.table{
  overflow-x: scroll;
}
.table thead th{
  white-space: 
  ;
}
.product-search-input{
  box-shadow: 0px 1px 10px #40404029;
}
.b-pagination li span{
    background-color: transparent !important;
    /* padding: .5rem; */
    border: 0px !important;
    align-items: center;
    text-align: center;
}
.b-pagination{
  margin: 0 !important;
}
.page-item.active .page-link{
  border:0px !important;
  box-shadow: 0px 1px 10px #40404029;
  color:#222838 !important;
  font-weight: 600;
}
.page-link{
    background-color: transparent !important;
    padding: .5rem .2rem;
    border: 0px !important;
    height: 30px !important;
    min-width: 30px !important;
    font-size: 18px !important;
}
.page-item .page-link{
    font-size: 20px !important;
}
.page-item.active .page-link{
  font-size: 14px !important;
}

.shipment-details{
  box-shadow: 0px 1px 3px #00000029;
}
.shipment-details ::v-deep .card-body{
  padding: 9px 30px 10px 25px!important;
}
.button-row{
  background: #fff;
  border-radius: 5px;
}
.button-row ::v-deep .shipment-buttons{
  margin-top: 0 !important;
}
.activeTab {
  background: #2185d0;
  color: #fff !important;
  border-radius: 20px;
  transition: all 0.2s ease;
}
.button-groups{
  border: 0.7px solid #2185D0;
  border-radius: 30px;
  margin-top: 3px;
  white-space: 
  ;
}
.button-groups button {
  font-size: 11px;
  font-weight: 600;
  color: #2185d0;
  border: none;
  min-width: 140px;
  padding: 7px 15px;
}
.info-icon {
  height:18px;
  margin-bottom:1px;
  cursor: default;
}
</style>
